<template>
  <div class="sidebar-item social-sidebar">
    <div class="title">
      <h4>Siguenos en</h4>
    </div>
    <div class="sidebar-info">
      <ul class="px-0">
        <li
          class="mr-2 mb-2"
          v-for="(l, index) in links"
          :key="index"
          :class="l.name"
        >
          <a :href="l.redirecTo" target="_blank">
            <i :class="l.icon"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { id: 1, icon: "fab fa-facebook-f", name: "facebook", redirecTo: "https://www.facebook.com/enfoqueaboralmx" },
        { id: 1, icon: "fab fa-twitter", name: "twitter", redirecTo: "https://twitter.com/enfoquelaboralm" },
        { id: 1, icon: "fab fa-instagram", name: "linkedin", redirecTo: "https://www.instagram.com/p/CWG3RI-NmYC/" },
      ],
    };
  },
};
</script>
