<template>
  <div class="sidebar-item search">
    <div class="title">
      <h4>Notas Relevantes</h4>
    </div>
    <div class="sidebar-info">
      <form>
        <input v-model.trim="text" type="text" class="form-control" />
        <input type="submit" value="Nota del Mes" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
    };
  },
};
</script>
