<template>
  <div class="blog-area full-blog right-sidebar full-blog default-padding">
    <div class="container">
      <div class="row">
        <div class="blog-items">
          <!-- left -->
          <div class="blog-content col-xs-12 col-lg-8">
            <div v-for="article in articles" :key="article.id">
              <BoxArticle :article="article"/>
            </div>

          </div>

          <!-- rigth -->
          <div class="sidebar col-xs-12 col-lg-4">
            <aside>
              <!--              <SearchPost />-->
              <!--              <CategoriesList />-->
              <div class="sidebar-item recent-post">
                <div class="title">
                  <h4>Notas Recientes</h4>
                </div>
                <div v-for="(r, index) in recent" :key="index">
                  <RecentPost :recent="r"/>
                </div>
              </div>
              <SocialSidebar/>
            </aside>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesList from "../components/blog/CategoriesList.vue";
import PaginationPost from "../components/blog/PaginationPost.vue";
import RecentPost from "../components/blog/RecentPost.vue";
import SearchPost from "../components/blog/SearchPost.vue";
import SocialSidebar from "../components/blog/socialSidebar.vue";
import BoxArticle from "../components/blog/BoxArticle.vue";
import {mapActions} from "vuex";

export default {
  components: {
    BoxArticle,
    CategoriesList,
    RecentPost,
    SocialSidebar,
    SearchPost,
    PaginationPost,
  },
  head: {
    // To use "this" in the component, it is necessary to return the object through a function
    title: function () {
      return {
        inner: "Notas Relevantes",
      };
    },
  },
  data() {
    return {
      articles: [],
      recent: [],
    };
  },
  methods: {
    ...mapActions('blog', ['getNotes', 'getRecent']),
    async initialize() {
      this.articles = await this.getNotes();

    },
    async recents() {
      this.recent = await this.getRecent();
    }
  },
  async created() {
    await this.initialize()
    await this.recents()
  }
};
</script>
