<template>
  <div class="sidebar-item category">
    <div class="title">
      <h4>Categorias Enfoque Laboral</h4>
    </div>
    <div class="sidebar-info">
      <ul>
        <li v-for="(category, index) in categories" :key="index">
          <a href="#">{{ category.title }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        { id: 1, title: "Emprendedor" },
        { id: 2, title: "PYmes" },
        { id: 3, title: "Empresas Responsables" },
        { id: 4, title: "Corporativos" },
      ],
    };
  },
};
</script>
