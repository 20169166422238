<template>
  <div class="item-box mb-10">
    <div class="item">
      <div class="thumb">
        <router-link :to="`/nota/${article.slug}`">
          <img :src="article.img" alt="Thumbnail" />
          <div class="overlay-icon">
            <i class="fa fa-images"></i>
          </div>
        </router-link>
      </div>
      <div class="info">
        <h4>
          <router-link :to="`/nota/${article.slug}`"> {{ article.title }}</router-link>
        </h4>
        <div class="meta">
          <ul class="px-0">
            <li>
            <i class="fas fa-user"></i> Admin
            </li>
            <li><i class="fas fa-calendar-alt"></i>{{ reformatDate(article.createdAt) }}</li>
          </ul>
        </div>
        <p>
          {{ article.description_crop }}
        </p>
        <router-link
          class="btn btn-theme effect btn-sm"
          :to="`/nota/${article.slug}`"
          >Leer mas</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.item-box img {
  width: 100%;
}
</style>
