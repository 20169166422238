<template>
  <div class="col-md-12 pagi-area">
    <nav aria-label="navigation">
      <ul class="pagination px-0">
        <li><a href="#">Previous</a></li>
        <li class="active"><a href="#">1</a></li>
        <li><a href="#">2</a></li>
        <li><a href="#">3</a></li>
        <li><a href="#">Next</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {};
</script>
