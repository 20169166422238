<template>

    <ul class="px-0">
      <li>
        <div class="thumb">
          <router-link  :to="`/nota/${articles.slug}`">
            <img :src="articles.img" alt="Thumb" />
          </router-link>
        </div>
        <div class="info">
          <router-link  :to="`/nota/${articles.slug}`">{{ articles.title }} </router-link>
          <div class="meta-title">
            <span class="post-date">{{ reformatDate(articles.createdAt) }}</span>
          </div>
        </div>
      </li>
    </ul>

</template>

<script>
export default {
  props:{
    recent: Object
  },
  data() {
    return {
      articles: []
    };
  },
  async created(){
    console.log(this.recent)
     this.articles = this.recent
  }
};
</script>

<style scoped>
</style>
